.whole_footer {
  background-color: #272b3b;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.whole_footer h5 {
  color: white;
  font-size: 15px;
  letter-spacing: 1px;
}
.whole_footer a:hover {
  color: #b9972b;
}
.location_icon i {
  color: #a68147;
}
.location_icon img {
  width: 25px;
  border-radius: 50%;
  height: 25px;
}
.rows_part {
  margin-bottom: 10px;
}
.selects_type {
  width: 200px;
}
.rows_part p a {
  text-decoration: none;
  color: #7a7d85 !important;
}
.rows_part p a:hover {
  color: #a68147;
}
.rows_part p {
  color: #7a7d85;
  text-align: justify;
  margin: 0;
}
.selects_type {
  width: 200px;
}
.icon_type_what {
  background-color: #02cd7c;
  padding: 5px 10px;
  border-radius: 4px;
}
.icon_type_what i {
  color: white;
  font-size: 21px;
}
.icon_type_link {
  background-color: #14539a;
  padding: 5px 10px;
  border-radius: 4px;
}
.icon_type_link i {
  color: white;
  font-size: 21px;
}
.horizontal-1 {
  border: 2px solid white;
}
.class_footer {
  background-color: #272b3b;
  width: 100%;
  bottom: 0;
}
.class_footer p {
  text-align: center;
  color: white;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
}
.titles {
  font-size: 20px !important;
}
