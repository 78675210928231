.capable_container {
    background-image: url("../img/first.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.capabless_container{
    background-image: url("../img/second.jpg");
    object-fit: contain;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.capableity_container{
    background-image: url("../img/third.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.capables1_containers1{
    background-image: url("../img/fourth.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.capabilitie1{
    background-image: url("../img/second.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.services_rainvent b{
    font-size: 25px;
}
.services_rainvent p{
    font-size: 22px;
    text-align: justify;
    font-weight: 300;
}
.rainvent p{
    font-size: 54px !important;
    line-height: 66px;
}
.rainvent b{
    font-size: 54px !important;
    line-height: 66px;
}
.years p{
    font-size: 34px !important;
    line-height: 50px;
}

.capability_pages{
    background-color: white;
}
.capable_pages{
    background-color: white;
    padding: 60px;
}
.capabilities_pages{
    background-color: white;
    padding: 150px;
}

.widgets_of_services{
    padding: 70px;
}

.service__wizards{
    border: 2px solid #000;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-align: center;
    padding: 20px;
    margin-bottom: 15px;
    min-height: 270px;
    height: auto;
    transition: 1s;
}
.service__wizards:hover{
    background: #272b3b;
}
.service__wizards:hover p{
    color: #fff;
}
.service__wizards:hover h6{
    color: #fff;
}
.service__wizards img{
    width: 70px;
}
.service__wizards h6{
    color: #000;
    margin: 10px 0;
    font-size: 20px;
}