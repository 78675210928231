.service-form {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

.conatcts1 {
  text-align: center;
  font-size: 35px;
  margin-bottom: 20px;
}

.service-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.service-form input,
.service-form select,
.service-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-bottom: 1px solid black !important;
  border-radius: 0;
}

.service-form button {
  background-color: #02cd7c;
  color: white;
  padding: 16px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
}

.service-form button:hover {
  background-color: #02cd7c;
}
.services_rainvents1 {
  margin-top: -66px;
}
.check_type {
  display: flex;
  gap: 10px;
}
.check_type input {
  font-size: 10px !important;
}
.rememberto input {
  text-align: left !important;
}
.accept {
  font-weight: lighter;
}
