.work p {
    font-size: 56px;
}

.wework {
    font-size: 34px !important;
}

.strategy {
    font-size: 22px !important;
}

.bolds {
    font-size: 25px;
}

.image_section {
    position: relative;
}

.image_section img {
    width: 100%;
}

.text_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.topics p {
    font-size: 50px;
    color: #fff;
}

.works_types {
    padding: 150px;
}