.navbars a {
  color: black;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbars a:hover {
  color: #02cd7c;
}
.buttons button {
  background-color: #02cd7c !important;
  border: none;
  border-radius: 4pc;
  padding: 3px 20px;
  font-size: 15px;
}
.main_logos img {
  width: 100%;
  height: 57px;
}

.navbar-nav .nav-link {
  color: #000;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #02cd7c;
}

.navbar-nav .active {
  color: #02cd7c;
  font-weight: 500; 
}

/* .navbars{
  position: fixed;
  width: -webkit-fill-available;
  z-index: 9;
  top: 0;
} */