@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Lexend Deca", sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.btn,
.btn.active,
.btn.show {
  border-color: transparent !important;
}

.form-select:focus {
  border-color: transparent !important;
  border-bottom: 1px solid #000 !important;
  box-shadow: none !important;
}


