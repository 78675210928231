.termsbanerSection {
  background-image: url(../src/img/termspic.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.words_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.banerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 300px 0;
  text-align: center;
  font-weight: 700;
  line-height: 1.4em;
  font-size: 4.6666666666667rem;
  font-family: "Montserrat", sans-serif;
}

.left h5 {
  color: white;
}

.welcome_test {
  font-size: 30px;
  color: white;
}

.banertextgroup {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 9px 0;
  text-align: center;
}

.Invitation_form span {
  color: red;
}

.Invitation_form {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 4px;
  width: 65%;
}

.Invitation_form input{
  height: 33px !important;
}
.invite_forms h3 {
  text-align: center;
  align-items: center;
  justify-content: center;
}

/* .profileIMg img {
  width: 50% !important;
  height: 170px;
  border-radius: 4px;
} */

.profileIMg i{
  font-size: 124px;
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
}

.Submit_butts1 button{
  background-color: green;
  padding: 5px 20px;
  border-radius: 4px;
  color: white;
  border: none;
}

.Submit_butts1 {
  display: flex;
  justify-content: flex-end;
}

.form-label {
  font-size: 13px;
}

.form-control,
.form-select{
  font-size: 13px;
}

.Form_justification {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.invite__form_message {
  border-radius: 10px;
  box-shadow: 0 3px 8px #0000003d;
  max-width: 550px;
  padding: 20px;
  text-align: center;
}

.invite__form p, .invite__form_message p {
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.Success__taag {
  display: flex;
  justify-content: center;
  text-align: center;
}

.Success__taag i {
  font-size: 60px;
}

.footer_button button {
  background: #103c5e !important;
  border: 1px solid #103c5e !important;
  border-radius: 3px;
  font-size: 14px;
  height: 30px;
  padding: 0;
  transition: 1s;
  width: 100px;
}