.product_homes h2 {
  color: black;
  text-align: -webkit-match-parent;
}
.product_homes p {
  font-size: 22px;
  /* text-align: justify; */
  color: black;
  align-items: center !important;
}
.product_reads button {
  border-radius: 4pc;
  border: none;
  background-color: #02cd7c;
  padding: 2px 11px;
  color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.services_homes h2 {
  color: black;
  text-align: -webkit-match-parent;
  font-weight: bold;
}
.services_homes p {
  font-size: 22px;
  text-align: justify;
}
.pioneerabout {
  font-size: 25px;
}
.product_homes {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 65%;
}
