.clarity h2 {
  text-align: center;
  font-size: 65px;
  font-weight: 700;
}

.clarity p {
  text-align: center;
}

.clarity {
  width: 38%;
  margin: 50px auto;
  color: white;
}

.clarity p {
  font-size: 1.3rem;
}
.main {
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.page {
  opacity: 1;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.active {
  opacity: 1;
  overflow: visible;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -ms-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.first_page {
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
}

.second_page {
  background-color: #030d45;
  /* background-image: url("../img/about.png"); */
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.second_page p {
  color: #fff;
}

.third_page {
  background-color: #ffffff;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 9;
}

.fourth_page {
  background-color: #030d45;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9;
  position: relative;
}

.fifth_page {
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 9;
}

a {
  font-weight: 300;
  color: #adadad;
  text-decoration: none;
}

a:hover {
  color: black;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.nav {
  height: 30%;
  width: 1vw;
  position: fixed;
  top: 50%;
  bottom: 50%;
  right: 5%;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  justify-content: space-between;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
}

.nav_point {
  height: 15%;
  width: 0.12rem;
  cursor: pointer;
  z-index: 2;
  background-color: #999;
}

.nav_point:before {
  content: "";
  height: 15%;
  width: 10px;
  display: block;
  position: absolute;
  right: 0;
}

.nav_point:after {
  content: "";
  height: 19%;
  width: 10px;
  display: block;
  position: absolute;
  left: 0;
}

.nav_point:hover {
  background: #02cd7c;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.active_point {
  background: #02cd7c !important;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.caption {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: white;
  position: sticky;
  top: 35%;
  left: 12%;
}

.active .caption {
  transform: translate(0%, 0%);
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.logo {
  position: fixed;
  top: 9%;
  left: 22%;
  z-index: 7;
  text-decoration: none;
  color: #adadad;
  cursor: default;
}

.download {
  display: flex;
  position: fixed;
  bottom: 9%;
  left: 24%;
  z-index: 7;
}

.download li {
  color: #adadad;
  padding-right: 35px;
}

.social {
  position: fixed;
  bottom: 9%;
  left: 62%;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  z-index: 7;
}

.social a {
  padding-right: 1.5rem;
}

.hidden {
  overflow: hidden !important;
}

@media (max-width: 1199px) {
  .caption {
    font-size: 6rem;
  }
}

@media (max-width: 991px) {
  .caption {
    font-size: 5rem;
  }
}

@media (max-width: 767px) {
  .page {
    background-size: 20%;
  }

  .social {
    display: none;
  }

  .caption {
    font-size: 4.2rem;
  }
}

@media (max-width: 575px) {
  .page {
    background-size: 20%;
  }

  .social {
    display: none;
  }

  .caption {
    font-size: 2.5rem;
  }
}

.video-background {
  position: relative;
}

.video-background__inner {
  left: 0px;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-background__video {
  object-fit: cover;
  height: 100%;
  max-width: 100%;
}

.video-background__content {
  left: 0px;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
