.contact-form {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

.conatcts1 {
  text-align: center;
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: 700;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.contact-form input,
.contact-form textarea {
  border-bottom: 1px solid black !important;
  border: none;
  border-radius: 0;
}

.contact-form button {
  background-color: #bd9622;
  color: white;
  padding: 16px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
}

.contact-form button:hover {
  background-color: #bd9622;
}
.offices_our {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.imgage_section img {
  width: 90%;
  height: 350px;
}
.whole_bottom {
  margin-bottom: 40px;
}
.image_text {
  display: flex;
  align-items: center;
}
.address_type {
  top: 50%;
}
.second_part {
  margin-top: 20px;
}
