.main_serve {
  background-color: white;
  padding: 35px;
}
.main_serve{
  text-align: justify;
}
.mainServices1 img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 275px;
}
.blog_detail_heading{
  text-align: justify;
}
.mainServices1 img:hover {
  transform: scale(1.01);
  transition: transform 0.5s;
}
.mainServices1 b {
  color: #000;
  font-size: 21px;
}

.mainServices1:hover b {
  color: #fff;
}
.technology_type {
  font-size: 20px;
}
.mainServices1 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  border-radius: 8px;
  height: 100%;
  margin-bottom: 25px;
  transition: 1s;
  overflow: hidden;
  position: relative;
}

.mainServices1:hover {
  background: #272b3b;
}
.mainServices1:hover p {
  color: white;
}
.partners_maintime {
  padding: 15px;
}
.read_moreB button {
  font-size: 16px;
  border-radius: 4pc;
  padding: 3px 23px;
  background-color: #02cd7c;
  color: white;
  border: none;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.read_moreB {
  text-align: end;
  margin-top: -11px;
}
.blog_date_data {
  display: flex;
  gap: 20px;
}
.blog_date_data img {
  width: 450px !important;
}
.icon_sets2 i{
  cursor: pointer !important;
}
.maximizing-efficiency-and-accuracys1{
  width: 100% !important;
  height: 500px;
  border-radius: 4px;
}
.category_section{
  background: gray;
  border-radius: 4px;
}
.category_section h4{
  padding: 10px;
  margin: 0;
  color: white;
  font-size: medium;
}
.category_list ul li {
  list-style: none;
  cursor: pointer;
}
.category_list ul {
  margin: 0;
  padding: 0 10px;
  line-height: 32px;
}
.date_category{
  display: flex;
  justify-content: space-between;
}
.date_category p{
  font-size: 14px;
}
.top_heading{
  font-size: 19px;
}