.career_container {
    background-image: url("../img/second.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.careers_container{
    background-image: url("../img/first.jpg");
    object-fit: contain;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.careerst_container{
    background-image: url("../img/third.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.careers1_containers1{
    background-image: url("../img/fourth.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.careersst{
    background-image: url("../img/second.jpg");
    object-fit: contain;
    background-attachment: fixed;
    width: -webkit-fill-available;
    height: 150vh;
    object-position: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.rainvent p{
    font-size: 54px !important;
    line-height: 66px;
}
.rainvent b{
    font-size: 54px !important;
    line-height: 66px;
}
.years p{
    font-size: 34px !important;
    line-height: 50px;
}
.careers_pages{
    background-color: white;
    padding: 150px;
}