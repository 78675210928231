.capable_container {
  background-image: url("../img/first.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.capabless_container {
  background-image: url("../img/second.jpg");
  object-fit: contain;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.capableity_container {
  background-image: url("../img/third.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.capables1_containers1 {
  background-image: url("../img/fourth.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.capabilitie1 {
  background-image: url("../img/second.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.products_type h2 {
  font-size: 25px;
  color: #b9972b;
}

.products_type p {
  font-size: 20px;
  font-weight: 300;
}

.products_type b {
  color: #000;
  font-size: 22px;
}
.products_type:hover b{
  color: #fff;
}
.products_types1 p {
  font-size: 20px;
}

.products_type {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
  border: 1px solid #d5d5d54d;
  border-radius: 4px;
  text-align: center;
  min-height: 233px;
  height: auto;
}

.products_type:hover {
  background: #272b3b;
  transition: 1s;
}

.products_type:hover p {
  color: white;
}

.products1_rainvent img {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  box-shadow: #b9972b 15px 15px;
}
.products1_rainvent p{
  font-size: 22px;
  text-align: justify;
  font-weight: 300;
}
.products1_rainvent h2{
  font-weight: bold;
}
.products_types1 b {
  color: #b9972b;
}

.rainvent p {
  font-size: 54px !important;
  line-height: 66px;
}

.rainvent b {
  font-size: 54px !important;
  line-height: 66px;
}

.years p {
  font-size: 34px !important;
  line-height: 50px;
}

.capability_pages {
  background-color: white;
  /* padding: 35px; */
}
.products1_pages{
    background-color: white;
    padding: 60px;
}
.capabilities_pages {
  background-color: white;
  padding: 150px;
}

.capability_pages h3 {
  font-size: 30px;
  margin-bottom: 40px;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding: 60px 0 0;
}

