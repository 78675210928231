.about_homes h2 {
  color: white;
  text-align: -webkit-match-parent;
}
.about_homes p {
  font-size: 25px;
  color: white;
  align-items: center !important;
  padding: 0px 30px;
}
.about_homes button {
  border-radius: 4pc;
  border: none;
  background-color: #02cd7c;
  padding: 2px 11px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 55%;
}
.services_homes h2 {
  color: #fff;
  text-align: -webkit-match-parent;
  font-weight: bold;
  text-align: center;
}
.services_homes p {
  font-size: 22px;
  text-align: justify;
  color: #fff;
  text-align: center;
}
.pioneerabout {
  font-size: 25px;
}
.about_homes {
  text-align: center;
  width: 65%;
}

