.capable_container {
  background-image: url("../img/first.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.capabless_container {
  background-image: url("../img/second.jpg");
  object-fit: contain;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.capableity_container {
  background-image: url("../img/third.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.capables1_containers1 {
  background-image: url("../img/fourth.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.capabilitie1 {
  background-image: url("../img/second.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.services_rainvent b {
  font-size: 28px;
  color: #000;
}
.services_rainvent p {
  font-size: 23px;
  text-align: justify;
}
.rainvent p {
  font-size: 54px !important;
  line-height: 66px;
}
.rainvent b {
  font-size: 54px !important;
  line-height: 66px;
}
.years p {
  font-size: 34px !important;
  line-height: 50px;
}
/* .contains{
    margin-top: 30px;
} */
.capability_pages {
  background-color: white;
  /* padding: 100px; */
}
.capabilities_pages {
  background-color: white;
  padding: 150px;
}
.widgets3_of_services3 {
  padding: 70px;
}
.widgets3b_of_services3 {
  padding: 70px;
}
.service3__wizards3 {
  border: 2px solid #000;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  text-align: center;
  padding: 50px;
  margin-bottom: 15px;
  min-height: 477px;
  height: auto;
}
.service3__wizards3:hover{
  background:#272b3b ;
  transition: 1s;
}
.service3__wizards3:hover h6{
  color: #fff;
}
.service3__wizards3:hover p{
  color: #fff;
}
.service3__wizards3 img {
  width: 70px;
}
.service3__wizards3 h6 {
  color: #000;
  margin: 10px 0;
  font-size: 22px;
}
.service3b__wizards3 {
  border: 2px solid #000;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  text-align: center;
  padding: 50px;
  margin-bottom: 15px;
  min-height: 477px;
  height: auto;
}
.service3b__wizards3:hover{
  background: #272b3b;
  transition: 1s;
}
.service3b__wizards3 h6 {
  color: #000;
  margin: 10px 0;
  font-size: 22px;
}
.service3b__wizards3:hover h6{
  color: #fff;
}
.service3b__wizards3:hover p{
  color: #fff;
}
.service3b__wizards3 img {
  width: 70px;
}
