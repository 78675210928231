/* .about_container {
  background-image: url("../img/first.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.abouts_container {
  background-image: url("../img/second.jpg");
  object-fit: contain;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.aboutts_container {
  background-image: url("../img/third.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about_containers1 {
  background-image: url("../img/fourth.jpg");
  object-fit: contain;
  background-attachment: fixed;
  width: -webkit-fill-available;
  height: 150vh;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.about_rainvent b {
  font-size: 25px !important;
}
.about_rainvent p {
  font-size: 25px !important;
  text-align: justify;
}
.about_rainvents b {
  font-size: 23px;
}
.about_rainvents p {
  font-size: 18px;
  margin-top: 10px;
  text-align: justify;
}
.about_years b {
  font-size: 30px !important;
  color: #000;
  margin-left: 25px;
}
.heart_part {
  margin-top: 15px;
}
.heart_part i {
  color: #000;
}
.about_years ul li {
  list-style: none;
}
.abouts_years b {
  font-size: 27px;
  color: #000;
  margin-left: 25px;
}
/* .abouts_years i{
    margin-right: 10px;
} */
.abouts_years p {
  font-size: 20px;
  text-align: justify;
}
.abouts_years img {
  width: 100%;
  height: 100%;
  border-radius: 85px 3px 3px 85px;
  position: relative;
}
/* box-shadow: #b9972b 30px -30px 0px -15px; */

.abouts_years img:hover{
  transform: scale(1.01);
  transition: transform 0.5s;
}

.circleDiv:hover{
  transform: scale(1.01);
  transition: transform 0.5s;
}

.abouts_yearss img {
  width: 100%;
  /* box-shadow: #b9972b 30px -30px 0px -15px; */
  height: 80%;
  border-radius: 20px;
}

.circleDivs::after{
  content: '';
  width: 600px;
  height: 20px;
  border-radius: 4pc;
  background-color: #972921;
  position: absolute;
  transform: rotate(45deg);
  left: -117px;
  top: 249px;
  z-index: 0;
}

.circleDivs::before{
  content: '';
  width: 700px;
  height: 26px;
  border-radius: 4pc;
  background-color: #13297e;
  position: absolute;
  transform: rotate(45deg);
  left: -55px;
  top: 178px;
  z-index: 0;
}
.abouts_yearss img:hover{
  transform: scale(1.01);
  transition: transform 0.5s;
}

.aboutts_container img {
  width: 100%;
  /* box-shadow: #b9972b 30px -30px 0px -15px; */
  height: 100%;
  border-radius: 50px 50px 50px 50px;
}

.aboutts_container img:hover{
  transform: scale(1.01);
  transition: transform 0.5s;
}

.about_containers1 img {
  width: 100%;
  box-shadow: #b9972b 15px 15px;
  height: 100%;
}
.empo {
  font-size: 30px;
  /* font-weight: 700; */
}
.about_page {
  background-color: white;
  padding: 35px;
}
.about_img img {
  width: 100%;
  height: 100%;
  border-radius: 87px 0px 87px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about_img:hover img{
  transform: scale(1.01);
  transition: transform 0.5s;
}

.dot_image img{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: -20px;
}

.dot_image img:hover{
  transform: scale(1.01);
  transition: transform 0.5s;
}
.vision {
  color: #000;
  font-size: 30px !important;
  font-weight: bold !important;
}
.About_us {
  color: #000;
  font-size: 35px;
  font-weight: bold;
}
.pionner {
  text-align: justify;
}
.welcome {
  font-size: 20px;
  text-align: justify;
  font-weight: 300;
}
.sign_img {
  display: flex;
  align-items: baseline;
}
.our_vision {
  display: flex;
  gap: 10px;
}
.our_vision img {
  width: 35px;
  height: 25px;
  margin-top: 25px;
}
.our_vision p {
  font-size: 22px;
}
.strive {
  display: flex;
  gap: 10px;
}
.strive p {
  margin-left: 5px;
  text-align: justify;
  font-size: 22px;
}
.strive img {
  width: 30px;
  height: 29px;
  margin-top: 22px;
}
.compliences img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 275px;
}
.compliences img:hover {
  transform: scale(1.01);
  transition: transform 0.5s;
}
.compliences b {
  color: #000;
  font-size: 22px;
}
.technology_typeabout {
  font-size: 20px;
  text-align: justify;
}
.compliences {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  border-radius: 8px;
  height: 100%;
  transition: 1s;
  overflow: hidden;
}
.compliences:hover b {
  color: #fff;
}
.compliences:hover {
  background: #272b3b;
}
.compliences:hover p {
  color: white;
}
.about_stats {
  padding: 20px;
}
.experiences b {
  color: #b9972b;
}
.health_care b {
  color: #b9972b;
}
.partners_time {
  padding: 15px;
}

.aboutText{
  padding: 55px;
}