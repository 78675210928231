@import "~boxicons/css/boxicons.min.css";

.banneroverlay {
  background-color: #01162780;
}
.banneroverlay h1 {
  padding: 10px;
}

@media (max-width: 1400px) {
  .offcanvas-body {
    background-color: #000;
  }
}

.navbar-toggler {
  background-color: #fff;
  border-radius: 0;
}

.dropdown-menu {
  --bs-dropdown-bg: #fff !important;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.drop.dropdown button {
  background: transparent;
  border: none;
  color: #fff;
  padding: 8px 0;
}

.drop.dropdown button:hover {
  background: transparent;
  border: none;
  color: #1f9ed1;
}

.btn:first-child:active {
  background-color: transparent;
}

.Privacy-12 {
  padding: 70px 0;
}

.Privacy-12 h2 {
  font-size: 17px;
  font-weight: 600;
}

.Privacy-12 p {
  font-size: 12px;
}

.blog_card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
}

.carousel-caption {
  position: relative !important;
  left: 0% !important;
  right: 0% !important;
}

.carousel-control-next {
  display: none !important;
}

.carousel-control-prev {
  display: none !important;
}

.privacybanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 3.6666666666667rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.termsbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 3.6666666666667rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.customerbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 3.6666666666667rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.cancelbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 3.6666666666667rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.video-background {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.termsbanerSection {
  background-image: url(../img/termspic.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.privacybanerSection {
  background-image: url(../img/privacypic.avif);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.customerbanerSection {
  background-image: url(../img/cutsomerpolicypic.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.words_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.banerSection h1 {
  font-weight: 700;
  font-size: 4.6666666666667rem;
  text-align: center;
}

.left h5 {
  color: white;
}

.welcome_test {
  font-size: 30px;
  color: white;
}

.banertextgroup {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 9;
  position: relative;
}
