.navbar2_nav a {
    color: #fff;
}

.navbar2_nav2 a {
    color: #000 !important;
}

.navbar2_nav a:hover{
    color: #02cd7c ;
}
.navbar2_nav {
    position: fixed;
    top: 0;
    width: inherit;
    z-index: 99;
}

.buttons_types button {
    background-color: #02cd7c !important;
    border: none;
    border-radius: 4pc;
    padding: 3px 18px;
    font-size: 15px;
}

.navbar2_nav img {
    background-color: transparent;
    opacity: 0.8;
    padding: 3px;
}

.navbar2_nav .dropdown-toggle {
    white-space: nowrap;
    color: white !important;
    padding: 0;
}

.navbar2_nav2 .dropdown-toggle {
    white-space: nowrap;
    color: #000 !important;
}

.navbar2_nav .dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: white !important;
    border: 0;
    color: black !important;
    border-radius: var(--bs-dropdown-item-border-radius, 0);
}